<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Sub Group</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Equipment Name "
                    v-model="subGroup.subGroupName"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Sub Group Name'"
                    @blur="$v.subGroup.subGroupName.$touch()"
                    :is-valid="
                      $v.subGroup.subGroupName.$dirty
                        ? !$v.subGroup.subGroupName.$error
                        : null
                    "
                  />
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" />Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="saveSpeciality()"
            >
              <CIcon name="cil-check-circle" />Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" />Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Equipment",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      subGroupId: "",
      groupId: "",
      subGroup: {
        subGroupId: "0",
        groupId: "0",
        subGroupName: "",
      },

      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    subGroup: {
      subGroupName: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.groupId = this.$route.params.groupId;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.subGroupId = this.$route.params.subGroupId;
      this.groupId = this.$route.params.groupId;
      this.getUser();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.subGroupId = this.$route.params.subGroupId;
      this.groupId = this.$route.params.groupId;
      this.getUser();
    }
  },
  components: { datetime },
  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      //  this.$router.push({ name: 'Sub Groups' })
      this.$router.push({ name: "Equipment" });
    },
    // saveSpeciality() {
    //   this.$v.group.$touch();
    //   if (this.$v.group.$invalid) {
    //     console.log("form is invalid", JSON.stringify(this.$v.group));
    //   }else{
    //  this.apiPostSecure(process.env.VUE_APP_API_HOST + /emapp/web/save/sub/group/{groupId}", this.group
    //   ).then((post) => {
    //       this.$router.push({ name: "Sub Group" });
    //     });
    //   }

    // },

    saveSpeciality() {
      // fetch(process.env.VUE_APP_API_HOST + "/emapp/web/save/sub/group/"+this.groupId, {
      //   method: "POST",
      //   headers: {
      //     "Content-type": "application/json",
      //   },
      //   body: JSON.stringify(this.subGroup),
      // })
      this.apiPostSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/save/sub/group/" +
          this.groupId,
        this.subGroup
      ).then((subGroup) => {
        console.log(JSON.stringify(subGroup));
        // this.$router.push({ name: "Groups" });
        this.$router.push({ name: "Equipment" });
      });
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/subgroup/" +
          this.subGroupId
      )
        .then((response) => response.json())
        .then((data) => (this.subGroup = data));
    },
    // getRoles() {
    // fetch(process.env.VUE_APP_API_HOST +"/web/secure/meta/roles")
    //     .then((response) => response.json())
    //     .then( (data)  => (this.roles = data) );
    // },
  },
  mounted() {
    //this. getRoles();
  },
};
</script>
